import React from "react";
import { Aligned } from "components/aligned/AlignedDiv";

export const Enquiries = () => {
  return (
    <Aligned
      id="future-students-enquiries"
      style={{
        justifyContent: "space-around",
        flexDirection: "column",
        gap: "8px",
        marginTop: "50px",
        paddingTop: "50px",
      }}
    >
      <h3 style={{ textAlign: "center" }}>Future students enquiries</h3>
      <Aligned style={{ gap: "8px", justifyContent: "center" }}>
        <a className="ds-btn-secondary ds-icon-phone-call footer-cta" href="tel:1300-135-045" target="_">
          Call 1300 135 045
        </a>

        <a
          className="ds-btn-secondary ds-icon-chevron-right footer-cta"
          href="https://www.latrobe.edu.au/contact/chat"
          target="_"
        >
          Live chat
        </a>

        <a
          className="ds-btn-secondary ds-icon-users footer-cta"
          href="https://www.latrobe.edu.au/study/one-on-one-consultations"
          target="_"
        >
          Book 1:1 consultation
        </a>
      </Aligned>

      <p>
        <a
          className="ds-icon-chevron-right footer-cta"
          href="https://www.latrobe.edu.au/courses/bachelor-of-arts"
          target="_"
        >
          View course page
        </a>
      </p>
      <h3 id="current-students-enquiries" style={{ textAlign: "center" }}>
        Current enrolled students enquiries
      </h3>
      <a
        className="ds-btn-secondary ds-icon-chevron-right footer-cta"
        href="https://www.latrobe.edu.au/students/ask-us/contact"
        target="_"
      >
        Contact ASK La Trobe
      </a>
    </Aligned>
  );
};
