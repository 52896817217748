import { get } from "lib/fetch";
import { IStudyAttribute } from "../store/types";

export const GET_RECOMMENDATION = "quizRecommendation/GET_RECOMMMENDATION";

export const recommendationEndpoint = (selectedStudyAttributeIds: string[], allAttributes: IStudyAttribute[]) => {
  const selected = allAttributes.filter((attr) => selectedStudyAttributeIds.includes(attr._id));
  const query = selected.map((attr) => `${attr.type.toLowerCase()}=${attr._id}`).join("&");
  return `/query?${query}`;
};

export const getRecommendation = (selectedStudyAttributeIds: string[], allAttributes: IStudyAttribute[]) => ({
  type: GET_RECOMMENDATION,
  payload: get(recommendationEndpoint(selectedStudyAttributeIds, allAttributes)),
});
