import React from "react";
import { ICategory, IDiscipline, ITileId } from "store/types";
import { ImageListItem, ImageListItemBar } from "@material-ui/core";
import { getTileSelection } from "./disciplineTileConfig";
import { disciplineImage } from "lib/images";
import { onClickDisableHref } from "lib/anchor";
import styled from "styled-components";
import { Aligned } from "components/aligned/AlignedDiv";
import { DisciplineTagChip } from "components/disciplineDetails/tags";
import { primaryInput } from "detect-it";

interface IProps {
  tileClasses: any;
  category: ICategory;
  discipline: IDiscipline;
  image: string;
  selected: ITileId | null;
  onTileSelected: (e: any, dId?: string) => any;
}

export const TagChip = styled.div`
  height: 20px;
  left: 270px;
  top: 638px;
  background: #ffffff;
  border-radius: 20px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12.8px;
  line-height: 19px;
  color: #242424;
  padding: 0 10px;
`;

const TileContainer = ({ children, disciplineId }: any) => {
  return primaryInput === "mouse" ? (
    <a href={`/${disciplineId}`} onClick={onClickDisableHref}>
      {children}
    </a>
  ) : (
    <div style={{ display: "inline" }}>{children}</div>
  );
};

export const DisciplineTile = (props: IProps) => {
  const { category, image, discipline, selected: selectedTile, onTileSelected, tileClasses } = props;

  const state = getTileSelection(selectedTile, category, discipline);
  const onClick = (e: any) => onTileSelected(e, discipline._id);

  return (
    <ImageListItem
      key={discipline._id}
      className={`${primaryInput === "mouse" ? "ds-btn-secondary" : ""} ${tileClasses[state]}`.trim()}
      onClick={primaryInput === "mouse" ? onClick : undefined}
    >
      <Aligned
        style={{
          position: "absolute",
          top: "4px",
          left: "6px",
          flexDirection: "column",
          // gap: "2px",
          alignItems: "start",
          width: "100%",
        }}
      >
        {(discipline.tags ?? []).map((tag) => (
          <DisciplineTagChip key={tag} tag={tag} />
        ))}
      </Aligned>

      <TileContainer disciplineId={`/${discipline._id}`}>
        {/* TODO just use height="100%" when the images are OK */}
        <img
          src={disciplineImage(image, 240)}
          className="discipline-tile-image"
          alt={""}
          style={{
            maxHeight: "calc(100% - 52px)",
            // minWidth: "100%",
            minWidth: "100%",
            border: "1px solid #CCCCCC",
          }}
        />

        <ImageListItemBar
          position="bottom"
          title={discipline.name}
          {...({ onClick: primaryInput !== "mouse" ? onClick : undefined } as any)}
          style={{
            border: "1px solid #CCCCCC",
            paddingTop: "10px",
            paddingLeft: "10px",
            paddingBottom: "15px",
          }}
        />
      </TileContainer>
    </ImageListItem>
  );
};
