import * as React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { ISnackBarState } from "../../store/types";
import "./snackbar.css";

export interface IProps {
  snackState?: ISnackBarState | null;
  closeSnackBar: () => any;
}

const icons = {
  error: <i className="material-icons ibm__snackbar-icon">error</i>,
  success: <i className="material-icons ibm__snackbar-icon">check_circle</i>,
};

const SnackBar = ({ snackState, closeSnackBar }: IProps) => {
  return snackState ? (
    <Snackbar
      className={`ibm__snack_container ibm__snack-${snackState.type}`}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={snackState !== null && snackState !== undefined}
      autoHideDuration={3000}
      onClose={closeSnackBar}
      ContentProps={{
        "aria-describedby": "ibm__snack_bar_message_id",
        className: `ibm__snack_container ibm__snack-${snackState.type}`,
      }}
      message={
        <div id="ibm__snack_bar_message_id">
          <div>{icons[snackState.type]}</div>
          <div>&nbsp;{snackState.message}</div>
        </div>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={closeSnackBar}>
          <i className="material-icons">close</i>
        </IconButton>,
      ]}
    />
  ) : null;
};

export default SnackBar;
