import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { IState } from "store/types";
import { CategoryContainer } from "./Category";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { userPreferencesCategory } from "lib/userPreferencesCategory";
import { userSavedCategory } from "lib/userSavedCategory";
import { searchCategory } from "lib/searchCategory";
import { Personalisation } from "./Personalisation";
import { ResponsiveCenterBody } from "./Center";

const HiddenH2 = styled.h2`
  visibility: hidden;
`;

const H2 = styled.h2`
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 400;
  font-size: 31.25px;
  color: #242424;
  margin-bottom: 10px;
  padding-bottom: 0;
`;

const CustomWrapper = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  background: #eeeeee;
`;

export const CategoryListContainer = () => {
  const staticCategories = useSelector((state: IState) => state.categories.filter(Boolean));
  const userPreferences = useSelector((state: IState) => state.userPreferences);
  const quizRecommendations = useSelector((state: IState) => state.quizRecommendations);
  const searchState = useSelector((state: IState) => state.searchState);

  const userPrefCategory = useMemo(() => userPreferencesCategory(quizRecommendations), [quizRecommendations]);

  const userSaveCategory = useMemo(() => userSavedCategory(userPreferences), [userPreferences]);

  const searchResultsCategory = useMemo(() => searchCategory(searchState), [searchState]);

  return (
    <>
      <HiddenH2 />

      <CustomWrapper>
        <ResponsiveCenterBody>
          <Grid container>
            <Grid item xs={12}>
              <Personalisation />
            </Grid>
            {searchResultsCategory && (
              <Grid key={searchResultsCategory._id} item xs={12}>
                <CategoryContainer category={searchResultsCategory} />
              </Grid>
            )}
            {userPrefCategory && (
              <Grid key={userPrefCategory._id} item xs={12}>
                <CategoryContainer category={userPrefCategory} />
              </Grid>
            )}
            {userSaveCategory && (
              <Grid key={userSaveCategory._id} item xs={12}>
                <CategoryContainer category={userSaveCategory} />
              </Grid>
            )}
          </Grid>
        </ResponsiveCenterBody>
      </CustomWrapper>

      <ResponsiveCenterBody>
        <H2>Explore all majors</H2>
        <Grid container spacing={0}>
          {staticCategories.map((c) => (
            <Grid key={c._id} item xs={12}>
              <CategoryContainer category={c} />
            </Grid>
          ))}
        </Grid>
      </ResponsiveCenterBody>
    </>
  );
};
