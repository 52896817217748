import { fulfilled } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import { FORM_SEARCH, CLEAR_SEARCH } from "actions/search";

const search = (state = initialState.searchState, action: any) => {
  switch (action.type) {
    case fulfilled(FORM_SEARCH):
    case CLEAR_SEARCH:
      return action.payload;
    default:
      return state;
  }
};

export default search;
