import { get } from "lib/fetch";
import { getUserId } from "lib/userId";
import { recommendationEndpoint } from "./quizRecommendations";

export const INIT_STATE = "initialise/INITIALISE";

const loadInitialState = async () => {
  const [disciplines, categories, subjects, userPreferences, allStudyAttributes] = await Promise.all([
    get("/disciplines"),
    get("/categories"),
    get("/subjects"),
    get(`/users/${getUserId()}`),
    get(`/studyAttributes`),
  ]);

  const quizRecommendations = await get(recommendationEndpoint(userPreferences.studyAttributeIds, allStudyAttributes));

  return { disciplines, categories, subjects, userPreferences, allStudyAttributes, quizRecommendations };
};

export const initialiseState = () => ({
  type: INIT_STATE,
  payload: loadInitialState,
});
