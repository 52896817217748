export const focus = (targetId: string | null, delay = 0) => {
  if (delay <= 0) {
    const target = targetId && document.getElementById(targetId);
    if (target) {
      target.focus();
    }
  } else {
    setTimeout(() => focus(targetId, 0), delay);
  }
};

export const blur = (targetId: string | null) => {
  const target = targetId && document.getElementById(targetId);
  if (target) {
    target.blur();
  }
};
