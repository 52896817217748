import { ClickAwayListener, Theme, Tooltip, withStyles } from "@material-ui/core";
import { Aligned } from "components/aligned/AlignedDiv";
import React, { MouseEventHandler } from "react";
import styled from "styled-components";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { scrollVertically } from "lib/scrollTo";
import { primaryInput } from "detect-it";

export const disciplineTagNames: Record<string, string> = {
  IsBAMajor: "Arts",
  IsOpenAccess: "Open access",
  IsDouble: "Double degree only",
};

const TagChipWrapper = styled.div`
  padding: 2px 2px;
  max-width: calc(100% - 36px);
  width: fit-content;
`;

const TagChip = styled.div`
  height: 20px;
  background: #ffffff;
  border-radius: 20px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12.8px;
  line-height: 19px;
  color: #242424;
  padding: 0 10px;
  width: fit-content;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const P = styled.p`
  padding: 0px;
  margin: 0px;
`;

const LightTooltip = withStyles((_theme: Theme) => ({
  tooltip: {
    background: "#EBF8F7",
    border: "2px solid #00AA98",
    borderRadius: "2px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    padding: "8px !important",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
}))(Tooltip);

const makeTooltipContent = (tag: string, onNavigate?: () => any) => {
  const onLinkClick: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    scrollVertically("future-students-enquiries");
    if (onNavigate) {
      onNavigate();
    }
  };

  switch (tag) {
    case "IsBAMajor":
      return (
        <>
          <P>
            This is an Arts major. In Bachelor of Arts, you must complete at least one Arts major (120 credit points).
          </P>
          <a href="#" onClick={onLinkClick}>
            <Aligned style={{ gap: "8px" }}>
              <P>Enquire about this major</P> <ExpandMoreIcon fontSize="small" />
            </Aligned>
          </a>
        </>
      );
    case "IsOpenAccess":
      return (
        <>
          <P>Open Access Major is open to all La Trobe students.</P>
          <P>
            In this course, after selecting one Arts major, you can also select one additional major, it can be either
            Arts or non-Arts major.
          </P>
          <a href="#" onClick={onLinkClick}>
            <Aligned style={{ gap: "8px" }}>
              <P>Enquire about this major</P> <ExpandMoreIcon fontSize="small" />
            </Aligned>
          </a>
        </>
      );
    case "IsDouble":
      return (
        <>
          <P>
            {
              "This major is only available in a Bachelor of Arts double degree, which means you'll graduate with two degrees including Bachelor of Arts. However, this major is not included in Bachelor of Arts single degree."
            }
          </P>
          <a href="#" onClick={onLinkClick}>
            <Aligned style={{ gap: "8px" }}>
              <P>Enquire about this major</P> <ExpandMoreIcon fontSize="small" />
            </Aligned>
          </a>
        </>
      );
  }
};

export const DisciplineTagChip = ({ tag, onNavigate }: { tag: string; onNavigate?: () => any }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen((old) => !old);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div style={{ width: "100%" }}>
        <LightTooltip
          title={makeTooltipContent(tag, onNavigate) as any}
          interactive
          disableFocusListener={primaryInput === "touch"}
          disableHoverListener={primaryInput === "touch"}
          disableTouchListener={primaryInput === "touch"}
          open={primaryInput === "touch" ? open : undefined}
          PopperProps={{ style: { marginTop: 16, marginBottom: 16 } }}
        >
          <TagChipWrapper onClick={primaryInput === "touch" ? handleTooltipOpen : undefined}>
            <TagChip key={tag} style={{ userSelect: "none" }}>
              {disciplineTagNames[tag] ?? tag}
            </TagChip>
          </TagChipWrapper>
        </LightTooltip>
      </div>
    </ClickAwayListener>
  );
};

export const BorderDisciplineTagChip = ({ tag, onNavigate }: { tag: string; onNavigate?: () => any }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen((old) => !old);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div style={{ width: "auto" }}>
        <LightTooltip
          title={makeTooltipContent(tag, onNavigate) as any}
          interactive
          disableFocusListener={primaryInput === "touch"}
          disableHoverListener={primaryInput === "touch"}
          disableTouchListener={primaryInput === "touch"}
          open={primaryInput === "touch" ? open : undefined}
          PopperProps={{ style: { marginTop: 14, marginBottom: 14 } }}
        >
          <TagChip
            key={tag}
            style={{ border: "1px solid #414141", userSelect: "none" }}
            onClick={primaryInput === "touch" ? handleTooltipOpen : undefined}
          >
            {disciplineTagNames[tag] ?? tag}
          </TagChip>
        </LightTooltip>
      </div>
    </ClickAwayListener>
  );
};
