import React from "react";
import { ISubject } from "store/types";
import { Card, CardContent } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { makeStyles } from "@material-ui/styles";
import { brandRed } from "theme/colors";

interface IProps {
  subject: ISubject;
  width: Breakpoint;
  onClick: () => any;
}

const tileFontSizePerWidth = {
  xs: "14px",
  sm: "14px",
  md: "16px",
  lg: "16px",
  xl: "16px",
};

const useStyles = (width: Breakpoint) =>
  makeStyles({
    card: {
      // width: tileWidthPerWidth[width],
      // minHeight: "150px",
      height: "160px",
      backgroundColor: "#FAFAFA",
      border: "1px solid #CCCCCC",
      // marginRight: "0.5vh",
      // marginTop: "0.5vh",
      padding: "0px",
      cursor: "pointer",
      textAlign: "left",
      "&:hover": {
        textDecoration: "none !important",
      },
      "&:hover .subject-name": {
        color: `${brandRed.toHexString()} !important`,
        textDecoration: "underline  !important",
      },
    },
    cardContent: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "16px",
      lineHeight: "24px",
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      // padding: "8px",
      margin: "0px",
      height: "100%",
    },
    title: {
      // fontWeight: "bolder",
      margin: 0,
      fontSize: tileFontSizePerWidth[width],
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      // fontSize: "16px",
      lineHeight: "24px",

      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": 4 /* number of lines to show */,
    },
  });

export const SubjectTile = ({ subject, width, onClick }: IProps) => {
  const classes = useStyles(width)();

  return subject ? (
    <>
      <Card className={`${classes.card} ds-btn-secondary`} onClick={onClick} component={"button" as any}>
        <CardContent className={classes.cardContent}>
          <div className={classes.title}>{subject._id}</div>
          <div className={`${classes.title} subject-name`}>{subject.name}</div>
        </CardContent>
      </Card>
    </>
  ) : null;
};
