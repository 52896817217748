import { IUserPreference, ICategory } from "store/types";

export const SAVED_DISCIPLINE_ID = "saved-disciplines";
export const userSavedCategory = (user: IUserPreference | null): ICategory | null => {
  if (user && user.bookmarkedDisciplineIds.length > 0) {
    return {
      _id: SAVED_DISCIPLINE_ID,
      name: "My saved disciplines",
      disciplines: user.bookmarkedDisciplineIds,
    };
  }
  return null;
};
