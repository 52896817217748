import { ICategory, ISearch } from "store/types";

export const searchCategory = (search: ISearch | null): ICategory | null => {
  if (!search || search.results.length === 0) {
    return null;
  }

  return {
    _id: "based-on-search",
    name: "Based on your search",
    disciplines: search.results,
  };
};
