import React from "react";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import styled from "styled-components";
import { heightsPerWidth } from "components/disciplineTile/disciplineTileConfig";
import { createSideScrollCallback } from "lib/scrollTo";

interface IProps {
  targetId: string;
  width: Breakpoint;
  direction: "left" | "right";
}

const ScrollButtonContainer = styled.div`
  position: absolute;
  color: white;
  z-index: 30;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
  margin-bottom: 10px;
`;

const ScrollButtonWrap = styled.div`
  background: black;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  height: 70%;
  width: 100%;
  font-size: large;
  color: white;
  margin: 0rem;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  &:hover {
    opacity: 0.9;
  }
`;

export const widths = {
  xs: "0px",
  sm: "0px",
  md: "44px",
  lg: "44px",
  xl: "50px",
};

export const ScrollButton = React.memo((props: IProps) => {
  const { width, direction, targetId } = props;
  const hide = ["sm", "xs"].includes(width);

  return hide ? null : (
    <ScrollButtonContainer
      style={{
        [direction]: -18,
        height: heightsPerWidth[width],
        width: widths[width],
      }}
      onClick={createSideScrollCallback(targetId, direction)}
    >
      <ScrollButtonWrap style={{ [direction === "left" ? "paddingLeft" : "paddingRight"]: "0rem" }}>
        <i className="material-icons"> {direction === "left" ? "chevron_left" : "chevron_right"}</i>
      </ScrollButtonWrap>
    </ScrollButtonContainer>
  );
});
