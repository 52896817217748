import * as React from "react";
import { RouteComponentProps } from "@reach/router";

interface IRouteProps<IProps> {
  component: React.ComponentType<IProps & RouteComponentProps> | React.ComponentType<IProps>;
}

export function Route<IProps>(props: IProps & RouteComponentProps & IRouteProps<any>) {
  const Component = props.component;
  return <Component {...props} />;
}
