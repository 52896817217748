import { put } from "lib/fetch";
import { getUserId } from "lib/userId";

export const SAVE_STUDY_ATTRIBUTES = "userPreferences/SAVE_STUDY_ATTRIBUTES";
export const SAVE_STUDY_ATTRIBUTES_OPTIMISTIC = "userPreferences/SAVE_STUDY_ATTRIBUTES_OPTIMISTIC";
export const SAVE_BOOKMARKED_DISCIPLINES = "userPreferences/SAVE_BOOKMARKED_DISCIPLINES";
export const SAVE_BOOKMARKED_DISCIPLINES_OPTIMISTIC = "userPreferences/SAVE_BOOKMARKED_DISCIPLINES_OPTIMISTIC";
export const SAVE_BOOKMARKED_SUBJECTS = "userPreferences/SAVE_BOOKMARKED_SUBJECTS";
export const SAVE_BOOKMARKED_SUBJECTS_OPTIMISTIC = "userPreferences/SAVE_BOOKMARKED_SUBJECTS_OPTIMISTIC";

export const saveStudyAttributes = (attributes: string[]) => ({
  type: SAVE_STUDY_ATTRIBUTES,
  payload: put(`/users/${getUserId()}/studyAttributeIds`, attributes),
});

export const saveStudyAttributesOptimistic = (attributes: string[]) => ({
  type: SAVE_STUDY_ATTRIBUTES_OPTIMISTIC,
  payload: attributes,
});

export const saveBookmarkedDisciplines = (disciplines: string[]) => ({
  type: SAVE_BOOKMARKED_DISCIPLINES,
  payload: put(`/users/${getUserId()}/bookmarkedDisciplineIds`, disciplines),
});

export const saveBookmarkedDisciplinesOptimistic = (disciplines: string[]) => ({
  type: SAVE_BOOKMARKED_DISCIPLINES_OPTIMISTIC,
  payload: disciplines,
});

export const saveBookmarkedSubjects = (subjects: string[]) => ({
  type: SAVE_BOOKMARKED_SUBJECTS,
  payload: put(`/users/${getUserId()}/bookmarkedSubjectIds`, subjects),
});

export const saveBookmarkedSubjectsOptimistic = (subjects: string[]) => ({
  type: SAVE_BOOKMARKED_SUBJECTS_OPTIMISTIC,
  payload: subjects,
});
