import React, { useCallback, useEffect, useState } from "react";
import { ICategory, IState, IDiscipline } from "store/types";
import { Backdrop, Dialog, ImageList, makeStyles, Popover, withWidth } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import styled from "styled-components";
import { selectTile } from "actions/selectedTile";
import { DisciplineTile } from "components/disciplineTile";
import { colsPerWidth, heightsPerWidth, tileStyles } from "components/disciplineTile/disciplineTileConfig";
import { DisciplineDetails } from "components/disciplineDetails";
import { scrollVertically } from "lib/scrollTo";
import { ScrollButton } from "components/scrollButton";
import { randomItem } from "lib/random";
import { flipFavourites } from "lib/favorites";
import { focus } from "lib/focus";
import { saveBookmarkedDisciplines, saveBookmarkedDisciplinesOptimistic } from "actions/userPreferences";
import { onClickDisableHref } from "lib/anchor";
import memoize from "lodash/memoize";
import { SAVED_DISCIPLINE_ID } from "lib/userSavedCategory";
import { PREF_DISCIPLINE_ID } from "lib/userPreferencesCategory";
import { pluraliseMajors } from "lib/plural";
import { brandRed } from "theme/colors";
import { Favorite } from "@material-ui/icons";
import { IDictionary } from "lib/dictionary";

interface IProps {
  category: ICategory;
  width: Breakpoint;
}

const GridListWrapper = styled.div`
  margin-bottom: 1rem;
`;
const RedTxt = styled.span`
  color: ${brandRed.toHexString()};
`;

const useGridlistStyles = (_width: Breakpoint) =>
  makeStyles({
    gridList: {
      flexWrap: "nowrap",
      transform: "translateZ(0)",
      overflowY: "hidden",
      // height: heightsPerWidth[width],
      paddingBottom: "5px",
      position: "relative",
    },
  });

const useTileStyles = (width: Breakpoint) => makeStyles(tileStyles(width));

const _randomImg = (d: IDiscipline) => randomItem(d.images);
const randomImg = memoize(_randomImg, (...args) => args[0] && args[0]._id);

const getCategoryName = (category: ICategory, disciplines: IDictionary<IDiscipline>) => {
  const n = category.disciplines.filter((d) => disciplines[d]).length;
  switch (category._id) {
    case SAVED_DISCIPLINE_ID:
      return (
        <>
          <Favorite fontSize="small" style={{ fill: brandRed.toHexString() }} /> You have{" "}
          <RedTxt>
            {n} saved {pluraliseMajors(n)}
          </RedTxt>
        </>
      );
    case PREF_DISCIPLINE_ID:
      return (
        <>
          There are{" "}
          <RedTxt>
            {" "}
            {n} {pluraliseMajors(n)}{" "}
          </RedTxt>{" "}
          recommended based on your preferences
        </>
      );
    default:
      return category.name;
  }
};

export const CategoryContainer = withWidth()((props: IProps) => {
  const [majorAnchorEl, setMajorAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const { category, width } = props;
  const useModalForDetails = width === "xs";

  const catId = category._id;
  const gridLstContainerId = `gridlist-container-${catId}`;
  const gridListId = `gridlist-${catId}`;
  const detailsAnchorId = `a-${catId}`;

  const { disciplines, selectedTile, userPreferences } = useSelector((state: IState) => state);
  const subjects = useSelector((state: IState) => state.subjects);
  const selectedDiscId = selectedTile && selectedTile.disciplineId;
  const selectedCatId = selectedTile && selectedTile.categoryId;
  const bookmarkedDisciplineIds = userPreferences ? userPreferences.bookmarkedDisciplineIds : [];
  const currentDisciplineFavorited = bookmarkedDisciplineIds.find((e) => e === selectedDiscId);

  const dispatch = useDispatch();
  const doSelectTile = useCallback(
    async (dId: string) => {
      const newTile =
        selectedCatId === catId && selectedDiscId === dId ? null : { categoryId: catId, disciplineId: dId };
      dispatch(selectTile(newTile));
      focus(newTile && detailsAnchorId, 200);
      if (!useModalForDetails) {
        await scrollVertically(newTile && gridLstContainerId);
      }
    },
    [dispatch, catId, selectedDiscId, selectedCatId, detailsAnchorId, gridLstContainerId],
  );

  const onFavoriteClicked = useCallback(() => {
    const newFavourites = flipFavourites(selectedDiscId, bookmarkedDisciplineIds);
    dispatch(saveBookmarkedDisciplines(newFavourites));
    dispatch(saveBookmarkedDisciplinesOptimistic(newFavourites));
  }, [dispatch, selectedDiscId, bookmarkedDisciplineIds]);

  const doUnselectTile = useCallback(() => {
    setMajorAnchorEl(null);
    dispatch(selectTile(null));
  }, [dispatch]);

  // Manage the horizontal scroller state
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRigth, setCanScrollRigth] = useState(colsPerWidth[width] < category.disciplines.length);
  const recomputeHorizontalScrollButtonsPositions = useCallback(
    ({ target }: any) => {
      if (target) {
        setCanScrollLeft(target.scrollLeft > 0);
        setCanScrollRigth(target.scrollLeft + target.offsetWidth < target.scrollWidth - 1);
      }
    },
    [setCanScrollRigth, setCanScrollLeft],
  );

  // Recompute horizontal scroll buttons on number of discipline or width change
  useEffect(() => {
    recomputeHorizontalScrollButtonsPositions({ target: document.getElementById(gridListId) });
  }, [gridListId, category.disciplines.length, recomputeHorizontalScrollButtonsPositions, width]);

  const selectedDiscipline: IDiscipline | undefined = Object.values(disciplines).find(
    (d) => d._id === selectedDiscId && catId === selectedCatId && category.disciplines.includes(d._id),
  );

  const classes = useGridlistStyles(width)();
  const tileClasses = useTileStyles(width)();

  return (
    <div id={gridLstContainerId}>
      <GridListWrapper className="discipline-tiles-row">
        <h3>{getCategoryName(category, disciplines)}</h3>
        <div className="horizontal-swim-lane" style={{ position: "relative", height: heightsPerWidth[width] }}>
          {canScrollLeft && <ScrollButton targetId={gridListId} width={width} direction="left" />}
          {canScrollRigth && <ScrollButton targetId={gridListId} width={width} direction="right" />}

          <ImageList
            id={gridListId}
            className={classes.gridList}
            cols={colsPerWidth[width]}
            onScroll={recomputeHorizontalScrollButtonsPositions}
            style={{
              position: "absolute",
              zIndex: selectedDiscipline ? 25 : 15,
              left: 0,
              right: 0,
            }}
          >
            {category.disciplines.map((dId) => {
              const d = disciplines[dId];
              return (
                d &&
                DisciplineTile({
                  category,
                  tileClasses: tileClasses,
                  image: randomImg(d),
                  discipline: d,
                  selected: selectedTile,
                  onTileSelected: async (e) => {
                    const clickedTarget = e.currentTarget;
                    await doSelectTile(dId);
                    setMajorAnchorEl(clickedTarget);
                  },
                })
              );
            })}
          </ImageList>
        </div>
      </GridListWrapper>
      <>
        <Backdrop style={{ zIndex: 20 }} open={!!selectedDiscipline} onClick={doUnselectTile}></Backdrop>

        {!useModalForDetails && (
          <Popover
            open={!!majorAnchorEl && !!selectedDiscipline}
            anchorEl={majorAnchorEl}
            onClose={doUnselectTile}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{
              width: "min(100vw, 900px)",
              height: "min(100vh - 220px, 900px)",
            }}
            disablePortal={true}
            hideBackdrop={false}
            PaperProps={{ style: { height: "100%" } }}
          >
            <a href={`/${selectedDiscId}`} id={detailsAnchorId} onClick={onClickDisableHref}>
              {" "}
            </a>
            {selectedDiscipline && (
              <DisciplineDetails
                discipline={selectedDiscipline}
                subjects={subjects}
                width={width}
                handleClose={doUnselectTile}
                favorite={!!currentDisciplineFavorited}
                onFavoriteFlip={onFavoriteClicked}
              />
            )}
          </Popover>
        )}

        {useModalForDetails && (
          <Dialog fullScreen open={!!selectedDiscipline} onClose={doUnselectTile}>
            <a href={`/${selectedDiscId}`} id={detailsAnchorId} onClick={onClickDisableHref}>
              {" "}
            </a>
            {selectedDiscipline && (
              <DisciplineDetails
                discipline={selectedDiscipline}
                subjects={subjects}
                width={width}
                handleClose={doUnselectTile}
                favorite={!!currentDisciplineFavorited}
                onFavoriteFlip={onFavoriteClicked}
              />
            )}
          </Dialog>
        )}
      </>
    </div>
  );
});
