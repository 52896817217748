import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { QuizContent } from "../components/quiz";
import { IState } from "../store/types";
import { useSelector, useDispatch } from "react-redux";
import { saveStudyAttributes, saveStudyAttributesOptimistic } from "../actions/userPreferences";
import { getRecommendation } from "actions/quizRecommendations";
// import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import { Aligned } from "components/aligned/AlignedDiv";

const PreferencesButtton = styled(Button)`
  background: #0591f8 !important;
  border-radius: 30px !important;
`;

export const Personalisation = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const flip = useCallback(() => setOpen((f) => !f), [setOpen]);

  const allAttributes = useSelector((state: IState) => state.allStudyAttributes);
  const selectedAttributes = useSelector((state: IState) =>
    state.userPreferences ? state.userPreferences.studyAttributeIds : [],
  );

  const doSave = useCallback(
    (attributes: string[]) => {
      setOpen(false);
      dispatch(saveStudyAttributesOptimistic(attributes));
      dispatch(saveStudyAttributes(attributes));
      dispatch(getRecommendation(attributes, allAttributes));
    },
    [dispatch, setOpen, allAttributes],
  );

  const hasAttributes = selectedAttributes.length > 0;
  return (
    <>
      <Aligned style={{ gap: "16px", alignItems: "center" }}>
        <h4 style={{ margin: 0, padding: 0 }}>Personalise your options</h4>
        <PreferencesButtton variant="contained" onClick={flip} startIcon={<PersonOutlineOutlinedIcon />}>
          {hasAttributes ? "Update my preferences" : "Set my preferences"}
        </PreferencesButtton>
      </Aligned>
      <QuizContent
        open={open}
        allAttributes={allAttributes}
        selectedAttributes={selectedAttributes}
        onClose={doSave}
        onCancel={flip}
      />
    </>
  );
};
