import React, { useState } from "react";
import styled from "styled-components";
import { Grid, Hidden, Tab, Tabs } from "@material-ui/core";
import { Aligned } from "components/aligned/AlignedDiv";
import { scrollVertically } from "lib/scrollTo";
import { brandBlack } from "theme/colors";

const H1 = styled.h1`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 0;
  margin-top: 0;
`;

const H2 = styled.h2`
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Knockout";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  text-transform: uppercase;
  color: ${brandBlack.toHexString()};
`;

export const AppBanner = () => {
  const [tab, setTab] = useState(0);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={5} xs={12}>
          <H1>Bachelor of Arts</H1>
          <H2>MAJOR EXPLORER</H2>

          <Tabs
            value={tab}
            style={{ marginRight: "1rem" }}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_event, newValue) => {
              setTab(newValue);
            }}
          >
            <Tab label="For future students" />
            <Tab label="For enrolled students" />
          </Tabs>

          {tab === 0 && (
            <p>
              With our flexible Bachelor of Arts, you have access to a wide range of majors both from Arts and across
              the university (Open Access majors). Choose an Arts major, and explore Open Access options from across the
              university. You can use this tool to explore the combinations and decide what works best for you and your
              future path.
            </p>
          )}

          {tab === 1 && (
            <p>
              {`Choose one Arts major then explore your interests or build an essential skill by choosing an optional second major. Your second major can be an Arts major, or it can be an Open Access major. You can also choose from a huge range of minors and/or electives from Arts and across the university. There are so many options! You'll graduate with your Arts major on your academic transcript, so future employers can recognise your expertise.`}
            </p>
          )}
          <Aligned style={{ gap: "8px", marginTop: "8px", marginBottom: "8px" }}>
            {tab === 0 && (
              <button
                className="ds-btn-primary ds-icon-chevron-down header-cta"
                onClick={() => scrollVertically("future-students-enquiries")}
              >
                Future students enquiries
              </button>
            )}

            {tab === 1 && (
              <button
                className="ds-btn-primary ds-icon-chevron-down header-cta"
                onClick={() => scrollVertically("current-students-enquiries")}
              >
                Enrolled students enquires
              </button>
            )}

            <a
              className="ds-btn-secondary ds-icon-chevron-right header-cta"
              href="https://www.latrobe.edu.au/courses/bachelor-of-arts"
              target="_"
            >
              View course page
            </a>
          </Aligned>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={7}>
            <img width="100%" src="/hero/Background image.png" />
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};
