import React from "react";
import { withWidth, makeStyles } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { brandBlack, brandRed } from "theme/colors";

const paddingLeftPerWidth = {
  xs: "1rem",
  sm: "1rem",
  md: "2rem",
  lg: "3rem",
  xl: "3rem",
};

const paddingRightPerWidth = {
  xs: "1rem",
  sm: "1rem",
  md: "6rem",
  lg: "9rem",
  xl: "9rem",
};

const useStyles = (width: Breakpoint) =>
  makeStyles({
    footer: {
      // backgroundColor: "#1a1b1d",
      width: "100%",
      maxWidth: "100%",
      padding: "1rem",
      paddingLeft: paddingLeftPerWidth[width],
      paddingRight: paddingRightPerWidth[width],
    },
    text: {
      width: "100%",
      maxWidth: "100%",
      color: brandBlack.toHexString(),
      lineHeight: "1.5rem",
      fontSize: 12,
      marginTop: 0,
    },
    bold: {
      color: brandBlack.toHexString(),
      lineHeight: "1.5rem",
      fontSize: 12,
      fontWeight: "bold",
      marginBottom: 0,
    },
    link: {
      color: brandRed.toHexString(),
      wordBreak: "break-word",
    },
  });

interface IProps {
  width: Breakpoint;
}
const AccessibilityNotice = withWidth()((props: IProps) => {
  const classes = useStyles(props.width)();
  return (
    <footer className={classes.footer} id="accessibility">
      <p className={classes.text}>
        The information contained here is general in nature, indicative and subject to change. For current and up to
        date information on the majors and minors you are viewing please refer to the La Trobe University Handbook
        making sure to refer to the entry that relates to your current or proposed study plan.
      </p>
      <p className={classes.bold}>Important</p>
      <p className={classes.text}>
        This site is an early release BETA version and may change without notice while the site continues to develop.
        The chrome browser on a mobile device is recommended for the best experience and we are aware that it may not
        work consistently across all devices and browsers.
      </p>
      <p className={classes.bold}>Accessibility</p>
      <p className={classes.text}>
        La Trobe strives for compliance with the Web Content Accessibility Guidelines (WCAG) 2.1 standards, and has
        worked towards this in this BETA version and acknowledge that some aspects of this site may not yet meet these
        standards. Alternative sources of the information presented on this site can be found in the University Handbook
        (
        <a href="https://handbook.latrobe.edu.au" target="_blank" rel="noopener noreferrer" className={classes.link}>
          https://handbook.latrobe.edu.au/
        </a>
        ) and the University Course Finder pages ({" "}
        <a href="https://www.latrobe.edu.au/" target="_blank" rel="noopener noreferrer" className={classes.link}>
          https://www.latrobe.edu.au/
        </a>
        ) if required.{" "}
      </p>
      {/* <p className={classes.bold}>Feedback</p>
      <p className={classes.text}>
        La Trobe have released this product in BETA and we are keen to hear from you on how we could improve this
        experience. Please email your feedback and suggestions to{" "}
        <a href="mailto:ba.explorer@latrobe.edu.au" className={classes.link}>
          ba.explorer@latrobe.edu.au
        </a>
        . Your valuable feedback will help refine and develop this product further.
      </p> */}
      <p className={classes.text}>
        © Copyright {new Date().getFullYear()} La Trobe University. All rights reserved. La Trobe University CRICOS
        Provider Code Number 00115M
      </p>
    </footer>
  );
});

export default AccessibilityNotice;
