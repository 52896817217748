import scrollIntoView from "smooth-scroll-into-view-if-needed";

// export const scrollToRef = (ref: MutableRefObject<any>) => window.scrollTo(0, ref.current.offsetTop);
export const scrollVertically = (id: string | null, delay = 0) => {
  const e = id && document.getElementById(id);
  if (delay === 0) {
    if (e) {
      const scrollMode = e.getBoundingClientRect().top > 100 ? "always" : "if-needed";
      if (e.getBoundingClientRect().top > 50) {
        return scrollIntoView(e, { behavior: "smooth", scrollMode, block: "start", inline: "start" });
      }
    }
    return Promise.resolve(null);
  } else {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        scrollVertically(id, 0)
          .then(() => resolve(null))
          .catch((e) => reject(e));
      }, delay);
    });
  }
};

// Based on http://jsfiddle.net/pGR3B/2/
export const createSideScrollCallback =
  (elementId: any, direction: "left" | "right", speed = 50, distance = 225, step = 25) =>
  () => {
    const container: any = document.getElementById(elementId);
    if (container) {
      let scrollAmount = 0;
      const slideTimer = setInterval(function () {
        if (direction === "left") {
          container.scrollLeft -= step;
        } else {
          container.scrollLeft += step;
        }
        scrollAmount += step;
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer);
        }
      }, speed);
    }
  };
