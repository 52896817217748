import React from "react";
import { makeStyles, FormControlLabel, Checkbox } from "@material-ui/core";
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from "@material-ui/icons";
import { closeIconSizePerWidth } from "components/disciplineTile/disciplineTileConfig";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { brandBlack } from "theme/colors";

export interface IProps {
  selected: boolean;
  width: Breakpoint;
  onChange: (e: any) => any;
  label: string;
}

export const useStyles = (width: Breakpoint) =>
  makeStyles({
    label: {
      fontSize: closeIconSizePerWidth[width],
      // color: "white",
    },
    unchecked: {
      fontSize: "30px",
      color: brandBlack.toHexString(),
    },
    checked: {
      fontSize: "30px",
      color: "#52C41A",
    },
  });

export const QuizCheckbox = React.memo((props: IProps) => {
  const { selected, width, onChange, label } = props;

  const classes = useStyles(width)();

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <FormControlLabel
        className={classes.label}
        control={
          <Checkbox
            checked={selected}
            onChange={onChange}
            icon={<CheckBoxOutlineBlankOutlined className={classes.unchecked} />}
            checkedIcon={<CheckBoxOutlined className={classes.checked} />}
            value="checkedH"
          />
        }
        label={label}
      />
    </div>
  );
});
