import { IState } from "./types";

const initialState: IState = {
  loading: false,
  submittingChanges: false,
  disciplines: {},
  subjects: {},
  categories: [],
  snackBarState: null,
  selectedTile: null,
  userPreferences: null,
  allStudyAttributes: [],
  searchState: null,
  quizRecommendations: [],
};

export default initialState;
