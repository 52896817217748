export const flipFavourites = (selectedDiscipline: string | null, disciplines: string[]) => {
  if (!selectedDiscipline) {
    return disciplines;
  }

  const currentDisciplineFavorited = disciplines.find((e) => e === selectedDiscipline);

  if (currentDisciplineFavorited) {
    return disciplines.filter((e) => e !== selectedDiscipline);
  } else {
    return [...disciplines, selectedDiscipline];
  }
};
