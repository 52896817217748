import React from "react";
import Grid from "@material-ui/core/Grid";

export const ResponsiveCenterHeader = ({ children }: { children: any }) => (
  <Grid
    container
    spacing={0}
    alignItems="center"
    justifyContent="center"
    style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
  >
    <Grid item xs={12} sm={11} md={10} lg={9} xl={8}>
      <div>{children}</div>
    </Grid>
  </Grid>
);

export const ResponsiveCenterBody = ({ children }: { children: any }) => (
  <Grid
    container
    spacing={0}
    alignItems="center"
    justifyContent="center"
    style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
  >
    <Grid item xs={12} sm={10} md={8} xl={6}>
      <div>{children}</div>
    </Grid>
  </Grid>
);
