import React, { useCallback, useState, useEffect } from "react";
import { Dialog, Slide, DialogContent, IconButton, makeStyles, withWidth, Grid, SlideProps } from "@material-ui/core";
import { IStudyAttribute, StudyAttributeType } from "store/types";
import styled from "styled-components";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { closeIconSizePerWidth } from "components/disciplineTile/disciplineTileConfig";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import groupBy from "lodash/groupBy";
import { IDictionary } from "lib/dictionary";
import { ResponsiveCenterBody } from "containers/Center";
import { QuizCheckbox } from "./Checkbox";
import { brandRed } from "theme/colors";

export interface IProps {
  open: boolean;
  width: Breakpoint;
  allAttributes: IStudyAttribute[];
  selectedAttributes: string[];
  onClose: (newSelectedAttributes: string[]) => any;
  onCancel: () => any;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide ref={ref} {...(props as SlideProps)} />;
});

const buttonSizePerWidth = {
  // [width, height]
  xs: ["133px", "38px"],
  sm: ["133px", "38px"],
  md: ["156px", "47px"],
  lg: ["156px", "47px"],
  xl: ["156px", "47px"],
};

export const useStyles = (width: Breakpoint) =>
  makeStyles({
    close: {
      fontSize: closeIconSizePerWidth[width],
    },
    btnContainer: {
      display: "flex",
      justifyContent: "center",
      position: "fixed",
      bottom: 0,
      padding: "1rem",
      background: "white",
      width: "100%",
      gap: "8px",
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column",
      paddingTop: "0rem",
    },
    title: {
      margin: 0,
    },
    subtitle: {
      margin: 0,
    },
    button: {
      minWidth: buttonSizePerWidth[width][0],
      minHeight: buttonSizePerWidth[width][1],
    },
    cancelButton: {
      minWidth: buttonSizePerWidth[width][0],
      minHeight: buttonSizePerWidth[width][1],
      borderColor: brandRed.toHexString(),
      borderWidth: "2px",
      textTransform: "none",
      color: brandRed.toHexString(),
    },
    dialogContent: {
      paddingBottom: "6rem",
    },
  });

const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`;

const sectionNames: IDictionary<string> = {
  [StudyAttributeType.INTEREST]: "I am interested in...",
  [StudyAttributeType.LIKE]: "I like...",
  [StudyAttributeType.FUTURE_WISH]: "In the future I would be happy to do...",
};
const sectionHints: IDictionary<string> = {
  [StudyAttributeType.INTEREST]: "Mark all of the interests below that relate to you.",
  [StudyAttributeType.LIKE]: "Mark all the options you feel you would enjoy.",
  [StudyAttributeType.FUTURE_WISH]: "Mark all of the options below that you like the sound of.",
};

export const QuizContent = withWidth()((props: IProps) => {
  const { open, allAttributes, selectedAttributes, onClose, width, onCancel } = props;

  const [selected, setSelected] = useState<string[]>(selectedAttributes);
  const onCloseCallback = useCallback(() => onClose(selected), [onClose, selected]);
  const isSelected = useCallback((id: string) => selected.includes(id), [selected]);

  // Reset when opening
  useEffect(() => {
    if (open) {
      setSelected(selectedAttributes);
    }
  }, [open, selectedAttributes]);

  const check = (id: string) => (_e: any) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((s) => s !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const classes = useStyles(width)();
  const attributeGroups = groupBy(allAttributes, (e) => e.type);

  return (
    <Dialog fullScreen open={open} onClose={onCloseCallback} TransitionComponent={Transition as any}>
      <DialogContent className={classes.dialogContent}>
        <ResponsiveCenterBody>
          <HeadContainer>
            <div className={classes.titleContainer}>
              <h1 className={classes.title}>Tell us a little about yourself...</h1>
              <h3 className={classes.subtitle}>We will identify disciplines that might be of interest to you.</h3>
            </div>
            <IconButton size="medium" aria-label="close" onClick={onCancel}>
              <CancelOutlinedIcon className={classes.close} />
            </IconButton>
          </HeadContainer>

          {Object.keys(sectionNames).map((type) => {
            const attributes = attributeGroups[type];
            if (!attributes || attributes.length === 0) {
              return null;
            }
            return (
              <React.Fragment key={type}>
                <div className={classes.titleContainer}>
                  <h1 className={classes.title}>{sectionNames[type]}</h1>
                  <p>{sectionHints[type]}</p>
                </div>

                <Grid container={true}>
                  {attributes.map((a) => (
                    <Grid key={a._id} item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <QuizCheckbox selected={isSelected(a._id)} width={width} onChange={check(a._id)} label={a.name} />
                    </Grid>
                  ))}
                </Grid>
              </React.Fragment>
            );
          })}
        </ResponsiveCenterBody>
        <div className={classes.btnContainer}>
          <button
            className="ds-btn-primary"
            style={{ minWidth: "8rem", display: "inline-flex", justifyContent: "space-around" }}
            onClick={onCloseCallback}
          >
            Save
          </button>
          <button
            className="ds-btn-secondary"
            style={{ minWidth: "8rem", display: "inline-flex", justifyContent: "space-around" }}
            onClick={onCancel}
          >
            Cancel
          </button>

          {/* <Button className={classes.button} variant="contained" onClick={onCloseCallback}>
            Save
          </Button>
          <Button className={classes.cancelButton} variant="outlined" onClick={onCancel}>
            Cancel
          </Button> */}
        </div>
      </DialogContent>
    </Dialog>
  );
});
