import { fulfilled, failed, pending } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import { INIT_STATE } from "actions/initialise";
import { setUserId } from "lib/userId";
import {
  SAVE_STUDY_ATTRIBUTES_OPTIMISTIC,
  SAVE_BOOKMARKED_DISCIPLINES_OPTIMISTIC,
  SAVE_BOOKMARKED_SUBJECTS_OPTIMISTIC,
} from "actions/userPreferences";

const userPreferences = (state = initialState.userPreferences, action: any) => {
  switch (action.type) {
    case failed(INIT_STATE):
    case pending(INIT_STATE):
      return null;
    case fulfilled(INIT_STATE):
      setUserId(action.payload.userPreferences._id);
      return action.payload.userPreferences;
    case SAVE_STUDY_ATTRIBUTES_OPTIMISTIC:
      return { ...state, studyAttributeIds: action.payload };
    case SAVE_BOOKMARKED_DISCIPLINES_OPTIMISTIC:
      return { ...state, bookmarkedDisciplineIds: action.payload };
    case SAVE_BOOKMARKED_SUBJECTS_OPTIMISTIC:
      return { ...state, bookmarkedSubjectIds: action.payload };
    default:
      return state;
  }
};

export default userPreferences;
