import { failed, fulfilled } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import { INIT_STATE } from "actions/initialise";
import { CLOSE_SNACKBAR, OPEN_SNACKBAR } from "actions/snackBar";
import { ActionType } from "redux-promise-middleware";
import { SAVE_STUDY_ATTRIBUTES, SAVE_BOOKMARKED_DISCIPLINES, SAVE_BOOKMARKED_SUBJECTS } from "actions/userPreferences";
import { FORM_SEARCH } from "actions/search";
import { ISearch } from "store/types";
import { GET_RECOMMENDATION } from "actions/quizRecommendations";

const err = (message: string, autoHideDuration = 30000) => ({
  type: "error",
  message,
  autoHideDuration,
});

// const _success = (message: string, autoHideDuration = 3000) => ({
//   type: "success",
//   message,
//   autoHideDuration,
// });

const snackBarState = (state = initialState.snackBarState || null, action: any) => {
  switch (action.type) {
    case CLOSE_SNACKBAR:
      return null;
    case OPEN_SNACKBAR:
      return action.payload;
    case failed(INIT_STATE):
      return err("Error loading disciplines");
    case failed(SAVE_STUDY_ATTRIBUTES):
      return err("Could not save your user preferences");
    case failed(SAVE_BOOKMARKED_DISCIPLINES):
      return err("Could not save your user disciplines");
    case failed(SAVE_BOOKMARKED_SUBJECTS):
      return err("Could not save your user preferences");
    case failed(FORM_SEARCH):
      return err("Could not perform search");
    case failed(GET_RECOMMENDATION):
      return err("Could not quiz recommendations");
    case fulfilled(FORM_SEARCH):
      if ((action.payload as ISearch).results.length === 0) {
        return err("No matching disciplines found!");
      } else {
        return state;
      }

    default:
      // Handle unknown errors
      if (action.type && (action.type as string).endsWith(ActionType.Rejected)) {
        return err("An unknown error has occurred");
      } else {
        return state;
      }
  }
};

export default snackBarState;
