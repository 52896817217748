import { ITileId, IDiscipline, ICategory } from "store/types";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { brandRed } from "theme/colors";
import { primaryInput } from "detect-it";

export const heightsPerWidth = {
  xs: "calc(7rem + 52px)",
  sm: "calc(8rem + 52px)",
  md: "calc(9rem + 52px)",
  lg: "calc(9rem + 52px)",
  xl: "calc(9rem + 52px)",
};

export const colsPerWidth = {
  xs: 2.5,
  sm: 3.5,
  md: 4.5,
  lg: 5.5,
  xl: 6.5,
};

export const closeIconSizePerWidth = {
  xs: "2rem",
  sm: "2.5rem",
  md: "2rem",
  lg: "2rem",
  xl: "2rem",
};

export const favoriteIconSizePerWidth = {
  xs: "1.6rem",
  sm: "1.6rem",
  md: "1.5rem",
  lg: "1.5rem",
  xl: "1.5rem",
};

export const detailsHeadingFontStyles: any = {
  xs: {
    headingFontSize: "2rem",
    headingLineHeight: "2rem",
    subHeadingFontSize: "1.17rem",
  },
  sm: {
    headingFontSize: "2rem",
    headingLineHeight: "2rem",
    subHeadingFontSize: "1.17rem",
  },
  md: {
    headingFontSize: "2rem",
    headingLineHeight: "2rem",
    subHeadingFontSize: "1.17rem",
  },
  lg: {
    headingFontSize: "2rem",
    headingLineHeight: "2rem",
    subHeadingFontSize: "1.17rem",
  },
  xl: {
    headingFontSize: "2rem",
    headingLineHeight: "2rem",
    subHeadingFontSize: "1.17rem",
  },
};

export enum TileSelectionState {
  SELECTED = "selected",
  ANOTHER_SELECTED = "another-selected",
  NONE_SELECTED = "none-selected",
}

export const getTileSelection = (selected: ITileId | null, cat: ICategory, d: IDiscipline): TileSelectionState => {
  if (!selected) {
    return TileSelectionState.NONE_SELECTED;
  } else if (selected.disciplineId === d._id && selected.categoryId === cat._id) {
    return TileSelectionState.SELECTED;
  } else {
    return TileSelectionState.ANOTHER_SELECTED;
  }
};

export const tileOpacities = {
  selected: 1,
  "another-selected": 0.3,
  "none-selected": 0.8,
};

const tileStyle = (width: Breakpoint, state: TileSelectionState) => ({
  display: "flex",
  flexDirection: "column",
  height: `${width && heightsPerWidth[width]} !important`,
  cursor: "pointer",
  opacity: tileOpacities[state],
  border: "none",
  // padding: "2px",
  alignItems: "normal !important",
  "&:hover": {
    border: "none",
    ...(primaryInput === "mouse" ? { padding: "2px 0px 0px 0px !important" } : {}),
    opacity: 1,
  },
  "&:hover .MuiImageListItemBar-titleWrap .MuiImageListItemBar-title": {
    color: `${brandRed.toHexString()} !important`,
    textDecoration: "underline",
  },
  "&:focus-within": {
    border: "2px solid transparent",
    opacity: 1,
  },
});

export const tileStyles = (width: Breakpoint): any => ({
  [TileSelectionState.ANOTHER_SELECTED]: tileStyle(width, TileSelectionState.ANOTHER_SELECTED),
  [TileSelectionState.NONE_SELECTED]: tileStyle(width, TileSelectionState.NONE_SELECTED),
  [TileSelectionState.SELECTED]: tileStyle(width, TileSelectionState.SELECTED),
});
