import { fulfilled, pending } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import { INIT_STATE } from "actions/initialise";
import { GET_RECOMMENDATION } from "actions/quizRecommendations";

const quizRecommendations = (state = initialState.quizRecommendations, action: any) => {
  switch (action.type) {
    case fulfilled(INIT_STATE):
      return action.payload.quizRecommendations;
    case pending(GET_RECOMMENDATION):
      return [];
    case fulfilled(GET_RECOMMENDATION):
      return action.payload;
    default:
      return state;
  }
};

export default quizRecommendations;
