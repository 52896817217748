import React from "react";
import { ISubjectGroup, IDisciplineDetail, ISubject } from "store/types";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import styled from "styled-components";
import { IDictionary } from "lib/dictionary";
import { SubjectGrid } from "./subjectGrid";

interface IProps {
  details: IDisciplineDetail;
  tagLine?: string;
  subjectGroups: ISubjectGroup[];
  subjects: IDictionary<ISubject>;
  width: Breakpoint;
}

const ScrollArea = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  box-shadow: 0 5px 10px -10px #222;
` as any;

export const DisciplineDetailsSection = React.memo((props: IProps) => {
  const { details, width, tagLine } = props;

  const htmlContent = details.content.trim().startsWith("<") ? details.content : `<p>${details.content}</p>`;

  if (details.type === "Content" && details.title.toLowerCase().trim() === "overview") {
    return (
      <ScrollArea width={width}>
        {tagLine && <h5 style={{ paddingTop: "0rem" }}>{tagLine}</h5>}
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </ScrollArea>
    );
  }

  return (
    <>
      {details.type === "Content" ? (
        <ScrollArea dangerouslySetInnerHTML={{ __html: htmlContent }} width={width} />
      ) : (
        <ScrollArea width={width}>
          <SubjectGrid {...props} />
        </ScrollArea>
      )}
    </>
  );
});
