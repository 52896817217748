import { fulfilled, failed, pending } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import keyBy from "lodash/keyBy";
import { INIT_STATE } from "actions/initialise";

const subjects = (state = initialState.subjects, action: any) => {
  switch (action.type) {
    case failed(INIT_STATE):
    case pending(INIT_STATE):
      return [];
    case fulfilled(INIT_STATE):
      return keyBy(action.payload.subjects, "_id");
    default:
      return state;
  }
};

export default subjects;
