import React, { useState, useCallback } from "react";
import { AppBar, makeStyles, InputAdornment, IconButton, withWidth } from "@material-ui/core";
import { Field, Formik, Form } from "formik";
import { FormikTextField } from "./formikTextComponent";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { focus, blur } from "../../lib/focus";
import styled from "styled-components";
import { brandBlack } from "theme/colors";

export const expandedSearchSize = {
  xs: "100%",
  sm: "100%",
  md: "300px",
  lg: "350px",
  xl: "450px",
};

export const fontSizes = {
  xs: "115%",
  sm: "110%",
  md: "110%",
  lg: "inherit",
  xl: "inherit",
};

const useStyles = (width: Breakpoint) =>
  makeStyles({
    appBar: {
      paddingLeft: "0rem",
      paddingRight: "0rem",
      width: "100%",
      height: "170px",
      boxShadow: "none",
    },
    textField: {
      // background: "white",
      margin: "0px",
      width: "100%",
    },
    textFieldInput: {
      // background: "white",
      border: "none",
      paddingRight: "0px",
      paddingLeft: "0px",
      marginLeft: "0px",
      color: brandBlack.toHexString(),
      width: "100%",
      fontSize: fontSizes[width],
      borderBottom: `1px solid ${brandBlack.toHexString()}`,
      "&:hover": {
        borderBottom: `1px solid ${brandBlack.toHexString()}`,
      },
      "&:focus": {
        borderBottom: `1px solid ${brandBlack.toHexString()}`,
      },
    },
    adornment: {
      margin: "0px",
      padding: "0px",
    },
  });

const CenterContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
`;

const CloseI = styled.i`
  font-size: 1.2rem;
`;
const SearchI = styled.i`
  font-size: 2.5rem;
`;

const searctInputId = "search-text-id-input";
const searchField = "disciplineSearchText";

export interface IProps {
  searchText: string;
  onSearch: (txt: string) => any;
  onClearSearch: () => any;
}

type IPropsWithWidth = IProps & { width: Breakpoint };

const Header = withWidth()((props: IPropsWithWidth) => {
  const { width, searchText, onSearch, onClearSearch: cancelSearch } = props;

  const classes = useStyles(width)();

  const [formForcedOpened, setFormForcedOpened] = useState(false);
  const closeIfEmpty = useCallback(() => setFormForcedOpened(false), [setFormForcedOpened]);

  const onSubmit = useCallback(
    (values: any) => {
      blur(searctInputId);
      onSearch(values[searchField]);
    },
    [onSearch],
  );

  const smallDevice = ["sm", "xs"].includes(width);

  return (
    <AppBar position="relative" className={classes.appBar}>
      <Formik initialValues={{ [searchField]: searchText }} onSubmit={onSubmit}>
        {(formState) => {
          const searchText = formState.values[searchField].trim();

          const onCloseBtnClick = () => {
            formState.setFieldValue(searchField, "");
            blur(searctInputId);
            cancelSearch();
          };

          const onActionBtnClicked = () => {
            setFormForcedOpened(true);
            focus(searctInputId, 250);
          };

          const showActionButton = !formForcedOpened && !searchText;
          const showLogo = showActionButton || !smallDevice;

          return (
            <HeaderContainer>
              {showLogo && <img src={"/logo/LaTrobe-Logo02.svg"} alt="Latrobe-Logo" height={60} width={155} />}
              {showActionButton && (
                <CenterContainer>
                  <IconButton onClick={onActionBtnClicked}>
                    <SearchI className="material-icons">search</SearchI>
                  </IconButton>
                </CenterContainer>
              )}
              <Form
                style={{
                  width: expandedSearchSize[width],
                  marginRight: "1rem",
                  display: showActionButton ? "none" : "inherit",
                }}
              >
                <Field
                  name={searchField}
                  component={FormikTextField}
                  autoComplete="off"
                  className={classes.textField}
                  fullWidth={true}
                  inputProps={{ id: searctInputId }}
                  placeholder="Search disciplines..."
                  InputProps={{
                    onBlur: closeIfEmpty,
                    className: classes.textFieldInput,
                    startAdornment: (
                      <InputAdornment className={classes.adornment} position="start">
                        <IconButton edge="start" type="submit">
                          <i className="material-icons">search</i>
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment className={classes.adornment} position="end">
                        {searchText && (
                          <IconButton edge="end" onClick={onCloseBtnClick}>
                            <CloseI className="material-icons">close</CloseI>
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Form>
            </HeaderContainer>
          );
        }}
      </Formik>
    </AppBar>
  );
});

export default Header;
