import { combineReducers } from "redux";
import snackBarState from "./snackBarState";
import loading from "./loading";
import submittingChanges from "./submittingChanges";
import categories from "./categories";
import disciplines from "./disciplines";
import subjects from "./subjects";
import selectedTile from "./selectedTile";
import userPreferences from "./userPreferences";
import allStudyAttributes from "./allStudyAttributes";
import searchState from "./searchState";
import quizRecommendations from "./quizRecommendations";

export default () =>
  combineReducers({
    submittingChanges,
    loading,
    disciplines,
    subjects,
    categories,
    snackBarState,
    selectedTile,
    userPreferences,
    allStudyAttributes,
    searchState,
    quizRecommendations,
  });
