import React, { useCallback, useState } from "react";
import { ISubjectGroup, IDisciplineDetail, ISubject } from "store/types";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import styled from "styled-components";
import { IDictionary } from "lib/dictionary";
import { Grid } from "@material-ui/core";
import { orderBy, uniq } from "lodash";
import { SubjectTile } from "components/subjectTile";
import { brandRed } from "theme/colors";

interface IProps {
  details: IDisciplineDetail;
  subjectGroups: ISubjectGroup[];
  subjects: IDictionary<ISubject>;
  width: Breakpoint;
}

const subjectCardWidths = {
  xs: 6,
  sm: 4,
  md: 3,
  lg: 3,
  xl: 4,
};

const SubjectCategory = styled.h6`
  color: ${brandRed.toString()};
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
`;

const SubjectDetailsContainer = styled.div`
  padding-top: 24px;
  overflow-y: hidden;
  height: 100%;
  & .subject-details-container {
    background: #fafafa;
    border: 1px solid #cccccc;
    margin-left: 8px;
    margin-top: 4px;
    padding: 12px;
    height: calc(100% - 40px);
    overflow-y: auto;
  }
`;

const YearLevel = ["", "First", "Second", "Third"];
const subjectSubheading = (s: ISubjectGroup) => {
  let subheading = "";
  const level = YearLevel[s.year];
  subheading += `${level} year ${s.type.toLowerCase()} subject${s.subjectCodes.length > 1 ? "s" : ""}`;
  return subheading;
};

export const SubjectGrid = React.memo(({ subjectGroups, width, subjects }: IProps) => {
  const [selectedSubjectCode, setSelectedSubjectCode] = useState<string | null>(null);
  const sz = subjectCardWidths[width];

  const computeGroupGridSz = useCallback((g: ISubjectGroup) => Math.min(12, g.subjectCodes.length * sz), [sz]);
  const computeGroupColumns = useCallback((g: ISubjectGroup) => computeGroupGridSz(g) / sz, [sz]);

  const years = orderBy(uniq(subjectGroups.map((g) => g.year)), (e) => e);

  if (selectedSubjectCode && subjects[selectedSubjectCode]) {
    return (
      <SubjectDetailsContainer>
        <a
          className="ds-icon--before ds-icon-chevron-left"
          href="#"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setSelectedSubjectCode(null);
          }}
        >
          Back to all subjects
        </a>
        <div className="subject-details-container">
          <p style={{ marginTop: "0px" }}>
            <strong>{selectedSubjectCode}</strong>
          </p>
          <p>
            <strong>{subjects[selectedSubjectCode].name}</strong>
          </p>
          <p>{subjects[selectedSubjectCode].description}</p>
        </div>
      </SubjectDetailsContainer>
    );
  }

  return (
    <>
      {years.map((year) => (
        <Grid key={year} container spacing={2} style={{ paddingRight: "1rem" }}>
          {subjectGroups
            .filter((g) => g.year === year)
            .map((g, gi) => (
              <Grid
                item
                key={gi}
                xs={computeGroupGridSz(g) as any}
                style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}
              >
                <SubjectCategory>{subjectSubheading(g)}</SubjectCategory>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(${computeGroupColumns(g)}, 1fr)`,
                    gap: "6px",
                  }}
                >
                  {g.subjectCodes.map((code, ci) => (
                    <SubjectTile
                      key={`${code}-${ci}`}
                      subject={subjects[code]}
                      width={width}
                      onClick={() => setSelectedSubjectCode(code)}
                    />
                  ))}
                </div>
              </Grid>
            ))}
        </Grid>
      ))}
    </>
  );
});
