import React from "react";
import { makeStyles, Tabs, Tab } from "@material-ui/core";
// import styled from "styled-components";

interface IProps {
  tabNames: string[];
  handleChange: (_e: any, newValue: any) => any;
  selectedTab: number;
}

const useStyles = () =>
  makeStyles({
    tab: {
      fontWeight: "bolder",
    },
  });

export const DisciplineTabs = React.memo(({ tabNames, handleChange, selectedTab }: IProps) => {
  const classes = useStyles()();

  return (
    <Tabs
      value={selectedTab}
      indicatorColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      onChange={handleChange}
    >
      {tabNames.map((n, i) => (
        <Tab key={i} label={n} className={classes.tab} />
      ))}
    </Tabs>
  );
});
