export const CLOSE_SNACKBAR = "snackbar/CLOSE_SNACKBAR";
export const OPEN_SNACKBAR = "snackbar/OPEN_SNACKBAR";

export const closeSnackBar = () => ({
  type: CLOSE_SNACKBAR,
  payload: {},
});

export const openSnackBar = () => ({
  type: CLOSE_SNACKBAR,
  payload: {},
});
