import { fulfilled, failed, pending } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import { INIT_STATE } from "actions/initialise";

const allStudyAttributes = (state = initialState.allStudyAttributes, action: any) => {
  switch (action.type) {
    case failed(INIT_STATE):
    case pending(INIT_STATE):
      return {};
    case fulfilled(INIT_STATE):
      return action.payload.allStudyAttributes;
    default:
      return state;
  }
};

export default allStudyAttributes;
