import React, { useState, useEffect, useCallback, useMemo } from "react";
import { IDiscipline, ISubject } from "store/types";
import { Paper, IconButton, makeStyles, Grid } from "@material-ui/core";
import { Close, Favorite, FavoriteBorder } from "@material-ui/icons";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import {
  closeIconSizePerWidth,
  favoriteIconSizePerWidth,
  detailsHeadingFontStyles,
} from "components/disciplineTile/disciplineTileConfig";
import styled from "styled-components";
import { randomItem } from "lib/random";
import { brandRed } from "theme/colors";
import memoize from "lodash/memoize";
import { DisciplineDetailsSection } from "./disciplineDetailsSection";
import { IDictionary } from "lib/dictionary";
import { DisciplineTabs } from "./tabs";
import { BorderDisciplineTagChip } from "./tags";
import { Aligned } from "components/aligned/AlignedDiv";

interface IProps {
  discipline: IDiscipline;
  subjects: IDictionary<ISubject>;
  width: Breakpoint;
  favorite: boolean;
  handleClose: () => any;
  onFavoriteFlip: () => any;
}

const useStyles = (width: Breakpoint, _img: string) =>
  makeStyles({
    close: {
      fontSize: closeIconSizePerWidth[width],
    },
    paper: {
      // backgroundImage: `${backgroundImagePerWidth[width]}, url("${disciplineImage(img, 480)}")`,
      // backgroundSize: "cover",
      // backgroundRepeat: "no-repeat",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      padding: ["xs"].includes(width) ? "0.5rem 1rem" : "1rem 1rem",
    },
    detailsContainer: {
      padding: "0rem 0.5rem 0.5rem 0.5rem",
      overflow: "hidden",
    },
    favoriteBtn: {
      margin: "0rem",
    },
    favorite: {
      color: brandRed.toString(),
      fontSize: favoriteIconSizePerWidth[width],
    },
    outlinedFavorite: {
      // color: "white",
      fontSize: favoriteIconSizePerWidth[width],
      "&:hover": {
        color: brandRed.toString(),
      },
    },
  });

const Heading = styled.h4`
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: start;
  font-size: ${({ width }: any) => detailsHeadingFontStyles[width as string].headingFontSize};
  line-height: ${({ width }: any) => detailsHeadingFontStyles[width as string].headingLineHeight};
` as any;
// const SubHeading = styled.h5`
//   font-size: ${({ width }: any) => detailsHeadingFontStyles[width as string].subHeadingFontSize};
//   margin-top: 0.2em;
//   margin-bottom: 0.5em;
// ` as any;

const _randomImg = (d: IDiscipline) => randomItem(d.images);
const randomImg = memoize(_randomImg, (...args) => args[0] && args[0]._id);

export const DisciplineDetails = ({ discipline, handleClose, width, favorite, onFavoriteFlip, subjects }: IProps) => {
  const { _id: disciplineId } = discipline;
  const [tab, setTab] = useState(0);

  const [extendedButonMode, setExtendedButonMode] = useState(false);

  const intro = discipline.details.find((d) => d.title.toLowerCase() === "intro");
  const details = discipline.details.filter((d) => d.title.toLowerCase() !== "intro");

  const currentDetails = details[tab];

  // Set the initial tab on discipine chage
  useEffect(() => setTab(0), [setTab, disciplineId]);

  const image = randomImg(discipline);
  const classes = useStyles(width, image)();
  const handleTabChange = useCallback((_e: any, newValue: any) => setTab(newValue), [setTab]);
  const tabNames = useMemo(() => details.map((d) => d.title), [discipline]);

  return (
    <Paper square={true} className={classes.paper}>
      <Aligned style={{ justifyContent: "space-between", alignItems: "start", flexWrap: "nowrap" }}>
        <Heading width={width}>
          <IconButton size="small" onClick={onFavoriteFlip} className={classes.favoriteBtn}>
            {favorite ? (
              <Favorite className={classes.favorite} />
            ) : (
              <FavoriteBorder className={classes.outlinedFavorite} />
            )}
          </IconButton>
          &nbsp;
          <div>{discipline.name}</div>
        </Heading>
        <IconButton size="small" aria-label="close" onClick={handleClose}>
          <Close className={classes.close} />
        </IconButton>
      </Aligned>
      <Aligned style={{ gap: "8px", padding: "6px 0 12px 36px" }}>
        {(discipline.tags ?? []).map((tag) => (
          <BorderDisciplineTagChip key={tag} tag={tag} onNavigate={handleClose} />
        ))}
      </Aligned>

      {intro && (
        <h4 style={{ marginTop: "1rem" }}>
          <div dangerouslySetInnerHTML={{ __html: intro.content }} />
        </h4>
      )}

      <DisciplineTabs tabNames={tabNames} handleChange={handleTabChange} selectedTab={tab} />
      <DisciplineDetailsSection
        details={currentDetails}
        tagLine={discipline.tagLine}
        subjectGroups={discipline.subjects}
        width={width}
        subjects={subjects}
      />

      <Grid container style={{ marginTop: "1rem" }} spacing={1}>
        {!extendedButonMode && (
          <Grid item xs={12}>
            <button
              className="ds-btn-primary  ds-icon-chevron-down"
              style={{ minWidth: "100%", display: "inline-flex", justifyContent: "center" }}
              onClick={() => setExtendedButonMode(true)}
            >
              Any questions? Ask us
            </button>
          </Grid>
        )}
        {extendedButonMode && (
          <>
            {/* {width === "xs" && ( */}
            <Grid
              item
              xs={12}
              onClick={() => setExtendedButonMode(false)}
              style={{ justifyContent: "end", display: "flex" }}
            >
              <button style={{ textDecoration: "none" }} className="ds-btn-link ds-icon-chevron-up">
                Hide
              </button>
            </Grid>
            {/* )} */}

            <Grid
              item
              xs={12}
              sm={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <p>
                <strong>Future students:</strong>
                <br /> Apply for the course first, and select the major after you enrol. For any questions,{" "}
                <a href="https://www.latrobe.edu.au/courses/help" className="major-cta" target="_">
                  contact us
                </a>
                .
              </p>
              <a
                className="ds-btn-secondary ds-icon-users major-cta"
                href="https://www.latrobe.edu.au/study/one-on-one-consultations"
                target="_"
              >
                Book 1:1 consultation
              </a>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <p>
                <strong>Enrolled students:</strong>
                <br /> If you need help selecting your major or add it to your study plan, please{" "}
                <a href="https://www.latrobe.edu.au/students/ask-us/contact" className="major-cta" target="_">
                  ASK LA Trobe
                </a>
                .
              </p>

              <a
                className="ds-btn-secondary ds-icon-chevron-right major-cta"
                href="https://www.latrobe.edu.au/students/ask-us/contact"
                target="_"
              >
                Contact ASK La Trobe
              </a>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};
