import { get } from "lib/fetch";
import { ISearch } from "store/types";

export const FORM_SEARCH = "search/FORM_SEARCH";
export const CLEAR_SEARCH = "search/CLEAR_SEARCH";

const performSearch = async (query: string): Promise<ISearch> => {
  const results = await get(`/search?searchText=${query}`);
  return {
    query,
    results,
  };
};

export const search = (query: string) => ({
  type: FORM_SEARCH,
  payload: performSearch(query),
});

export const clearSearch = () => ({
  type: CLEAR_SEARCH,
  payload: { query: "", results: [] } as ISearch,
});
