import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IState } from "store/types";
import { initialiseState } from "../actions/initialise";
import { search, clearSearch } from "../actions/search";
import { Router } from "@reach/router";
import { Route } from "components/route";
import Spinner from "components/spinner";
import { CategoryListContainer } from "./CategoryList";
import Header from "components/header";
import { ResponsiveCenterHeader } from "./Center";
import SnackBar from "components/snackbar";
import { closeSnackBar } from "actions/snackBar";
import AccessibilityNotice from "components/accessibilityNotice";
import styled from "styled-components";
import { AppBanner } from "./AppBanner";
import { Enquiries } from "components/enquiries";

const Skip = styled.a`
  background-color: #d34426;
  color: #fff;
  font-size: 18px;
  top: -100px;
  padding: 12px;
  position: fixed;
  -ie-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: top 0.3s;
  z-index: 10000;
  color: white !imporant;
  :visited: {
    color: white !imporant;
  }
  :focus {
    top: 12px;
  }
`;

const MainPageContainer = styled.div`
  padding: 0rem 0rem 15vh 0rem;
`;

export const App = () => {
  const loading = useSelector((state: IState) => state.loading);
  const searchState = useSelector((state: IState) => state.searchState);
  const submittingChanges = useSelector((state: IState) => state.submittingChanges);
  const snackBarState = useSelector((state: IState) => state.snackBarState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialiseState());
  }, [dispatch]);

  const performSearch = (query: string) => {
    const clearQuery = query.trim();
    dispatch(clearQuery ? search(clearQuery) : clearSearch());
  };
  const performClearSearch = () => dispatch(clearSearch());
  const doCloseSnackBar = () => dispatch(closeSnackBar());

  return (
    <>
      <MainPageContainer>
        <ResponsiveCenterHeader>
          <Skip id="skip" href="#accessibility" style={{ color: "white" }}>
            Skip to accessibility notice
          </Skip>
          <Header
            searchText={searchState ? searchState.query : ""}
            onSearch={performSearch}
            onClearSearch={performClearSearch}
          />
          <AppBanner />
        </ResponsiveCenterHeader>

        {submittingChanges && (
          <div className={submittingChanges ? "changes-in-progess-overlay" : ""}>
            <div className="loader" />
          </div>
        )}

        <Spinner loading={loading} />
        {!loading && (
          <Router>
            <Route path="/" exact={true} component={CategoryListContainer} />
          </Router>
        )}

        <Enquiries />
      </MainPageContainer>

      <ResponsiveCenterHeader>
        <SnackBar snackState={snackBarState} closeSnackBar={doCloseSnackBar} />
        {!loading && <AccessibilityNotice />}
      </ResponsiveCenterHeader>
    </>
  );
};
