import { IDictionary } from "lib/dictionary";

export interface IDisciplineDetail {
  title: string;
  type: "Content" | "Subject";
  content: string;
}

export interface ISubjectGroup {
  subjectCodes: string[];
  type: "Core" | "Core Choice";
  year: number;
}

export interface IDiscipline {
  _id: string; // code
  name: string;
  tagLine: string;
  handbookUrl: string;
  subjects: ISubjectGroup[];
  images: string[];
  keyWords: string[];
  studyAttributeIds: string[];
  details: IDisciplineDetail[];
  tags?: string[];
}

export interface ISubject {
  _id: string;
  name: string;
  description: string;
  handbookUrl: string;
  ilo: string[];
  domains: string[];
  elements: string[];
}

export interface ICategory {
  _id: string;
  name: any;
  disciplines: string[];
}

export interface ITileId {
  categoryId: string;
  disciplineId: string;
}

export interface IUserPreference {
  _id: string;
  studyAttributeIds: string[];
  bookmarkedDisciplineIds: string[];
  bookmarkedSubjectIds: string[];
}

export enum StudyAttributeType {
  FUTURE_WISH = "FutureWish",
  LIKE = "Like",
  INTEREST = "Interest",
}

export interface IStudyAttribute {
  _id: any;
  type: StudyAttributeType;
  name: string;
}

export interface ISnackBarState {
  type: "error" | "success";
  message: string;
  autoHideDuration: number;
}

export interface ISearch {
  query: string;
  results: string[];
}

export interface IRecommendation {
  dbId: string;
  confidence: number;
  disciplineOverlapCoeff: number;
  queryOverlapCoeff: number;
}

export interface IState {
  loading: boolean;
  submittingChanges: boolean;
  disciplines: IDictionary<IDiscipline>;
  subjects: IDictionary<ISubject>;
  categories: ICategory[];
  snackBarState: ISnackBarState | null;
  selectedTile: ITileId | null;
  userPreferences: IUserPreference | null;
  allStudyAttributes: IStudyAttribute[];
  searchState: ISearch | null;
  quizRecommendations: IRecommendation[];
}
