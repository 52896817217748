import * as React from "react";
import { TextField } from "@material-ui/core";

export const FormikTextField = ({
  label,
  field,
  form: { dirty, touched, errors, setTouched },
  field: { name, onChange },
  ...other
}: any) => {
  const errorText = errors[field.name];
  const hasError = dirty && touched[field.name] && errorText !== undefined;

  const touch = () => {
    if (!touched[name]) {
      setTouched({ ...touched, [name]: true });
    }
  };
  const onValChanged = (e: any, c: any) => {
    touch();
    onChange(e, c);
  };

  return (
    <TextField
      label={label}
      error={hasError}
      helperText={hasError ? errorText : ""}
      {...field}
      {...other}
      onChange={onValChanged}
      onBlur={touch}
    />
  );
};
