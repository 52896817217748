import { ICategory, IRecommendation } from "store/types";
import sortBy from "lodash/sortBy";

const sortRecommendations = (recommendations: IRecommendation[]) => {
  return sortBy(recommendations, [(r) => -r.disciplineOverlapCoeff, (r) => -r.confidence]).slice(0, 15);
};

export const PREF_DISCIPLINE_ID = "based-on-your-preferences";

export const userPreferencesCategory = (quizRecommendations: IRecommendation[]): ICategory | null => {
  if (quizRecommendations.length === 0) {
    return null;
  }

  return {
    _id: PREF_DISCIPLINE_ID,
    name: "Based on your preferences",
    disciplines: sortRecommendations(quizRecommendations).map((r) => r.dbId),
  };
};
