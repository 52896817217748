import initialState from "store/initialState";
import { fulfilled, failed, pending } from "lib/promiseMiddlewareTypes";
import { FORM_SEARCH } from "actions/search";
import { GET_RECOMMENDATION } from "actions/quizRecommendations";

const submittingChanges = (state = initialState.submittingChanges, action: any) => {
  switch (action.type) {
    case pending(FORM_SEARCH):
    case pending(GET_RECOMMENDATION):
      return true;
    case failed(FORM_SEARCH):
    case fulfilled(FORM_SEARCH):
    case failed(GET_RECOMMENDATION):
    case fulfilled(GET_RECOMMENDATION):
      return false;
  }
  return state;
};

export default submittingChanges;
